import style from './style.module.css';

export default function DocumentFocusedSvg({ color, styleName }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      className={style[styleName]}
      version="1.1"
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      viewBox="0 0 62.01 48.2"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          fill={color}
          d="M9.36 24.05l43.28 0c1.02,0 1.86,0.84 1.86,1.86 0,1.02 -0.84,1.86 -1.86,1.86l-43.28 0c-1.02,0 -1.86,-0.84 -1.86,-1.86 0,-1.02 0.84,-1.86 1.86,-1.86zm52.65 20.09l0 -31.04c-0.5,0.25 -1.06,0.4 -1.65,0.4l-4.46 0c-4.22,0 -7.65,-3.43 -7.65,-7.65l0 -4.46c0,-0.49 0.1,-0.96 0.28,-1.39l-44.48 0c-2.24,0 -4.06,1.82 -4.06,4.06l0 40.09c0,2.24 1.82,4.06 4.06,4.06l53.9 0c2.24,0 4.06,-1.82 4.06,-4.06zm-52.65 -13.02l43.28 0c1.02,0 1.86,0.84 1.86,1.86 0,1.02 -0.84,1.86 -1.86,1.86l-43.28 0c-1.02,0 -1.86,-0.84 -1.86,-1.86 0,-1.02 0.84,-1.86 1.86,-1.86zm0 6.83l43.28 0c1.02,0 1.86,0.84 1.86,1.86 0,1.02 -0.84,1.86 -1.86,1.86l-43.28 0c-1.02,0 -1.86,-0.84 -1.86,-1.86 0,-1.02 0.84,-1.86 1.86,-1.86zm25.76 -22.1c0,1.02 -0.84,1.86 -1.86,1.86l-23.93 0c-1.02,0 -1.86,-0.84 -1.86,-1.86 0,-1.02 0.84,-1.86 1.86,-1.86l23.93 0c1.02,0 1.86,0.84 1.86,1.86zm-1.86 -5.22l-23.93 0c-1.02,0 -1.86,-0.84 -1.86,-1.86 0,-1.02 0.84,-1.86 1.86,-1.86l23.93 0c1.02,0 1.86,0.84 1.86,1.86 0,1.02 -0.84,1.86 -1.86,1.86zm18.62 -4.78c0,2.22 1.8,4.02 4.02,4.02l4.46 0 -4.6 -4.6 -3.89 -3.89 0 4.46z"
        />
      </g>
    </svg>
  );
}
