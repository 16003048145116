import style from '../listStyles.module.css';

function ListItemHeader() {
  return (
    <div className={style.list__header}>
      <div className={style.header__img}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          space="preserve"
          width="100%"
          height="100%"
          version="1.1"
          style={{
            shapeRendering: 'geometricPrecision',
            textRendering: 'geometricPrecision',
            imageRendering: 'optimizeQuality',
            fillRule: 'evenodd',
            clipRule: 'evenodd',
          }}
          viewBox="0 0 76.78 67.36"
          xlink="http://www.w3.org/1999/xlink"
          xodm="http://www.corel.com/coreldraw/odm/2003"
        >
          <g id="Слой_x0020_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <path
              fill="#000"
              fillRule="nonzero"
              d="M49.64 0l-22.51 0c-5.04,0 -9.26,3.58 -10.25,8.33l-6.4 0c-5.78,0 -10.47,4.7 -10.47,10.48l0 38.08c0,5.78 4.7,10.48 10.47,10.48l55.83 0c5.77,0 10.47,-4.7 10.47,-10.48l0 -38.08c0,-5.78 -4.7,-10.48 -10.47,-10.48l-6.41 0c-0.99,-4.75 -5.21,-8.33 -10.25,-8.33zm0 4.3c3.41,0 6.17,2.76 6.17,6.17l0 2.16 10.49 0c3.4,0 6.17,2.76 6.17,6.17l0 38.08c0,3.41 -2.77,6.17 -6.17,6.17l-55.83 0c-3.41,0 -6.17,-2.76 -6.17,-6.17l0 -38.08c0,-3.41 2.77,-6.17 6.17,-6.17l10.49 0 0 -2.16c0,-3.41 2.77,-6.17 6.17,-6.17l22.51 0zm0.52 32.31c0,6.51 -5.27,11.78 -11.78,11.78 -6.5,0 -11.78,-5.27 -11.78,-11.78 0,-6.51 5.27,-11.78 11.78,-11.78 6.51,0 11.78,5.27 11.78,11.78zm9.63 0c0,11.82 -9.59,21.41 -21.41,21.41 -11.82,0 -21.41,-9.59 -21.41,-21.41 0,-11.82 9.59,-21.41 21.41,-21.41 11.82,0 21.41,9.58 21.41,21.41zm-3.71 0c0,-9.78 -7.92,-17.7 -17.7,-17.7 -9.77,0 -17.7,7.92 -17.7,17.7 0,9.77 7.92,17.7 17.7,17.7 9.78,0 17.7,-7.92 17.7,-17.7z"
            />
            <path
              fill="none"
              fillRule="nonzero"
              stroke="#000"
              strokeWidth="4.3"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M47.02 8.37c2.09,0 3.78,1.69 3.78,3.78"
            />
            <path
              fill="none"
              fillRule="nonzero"
              stroke="#000"
              strokeWidth="4.3"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              d="M64.14 18.02c1.98,0 3.59,1.61 3.59,3.59"
            />
          </g>
        </svg>
      </div>
      <p className={style.list__name}>Фото</p>
    </div>
  );
}

export default ListItemHeader;
