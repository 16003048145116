import styles from './avatar.module.css';

function Avatar({ currentUserAvatar, size }) {
  return (
    <div className={styles[size]}>
      {currentUserAvatar ? (
        <img
          src={`${process.env.REACT_APP_TEPTAR_HOST}/storage/avatars/${currentUserAvatar}`}
          alt="#"
        />
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.0083 17.112C39.7178 17.112 42.722 20.1162 42.722 23.8257C42.722 27.5353 39.7178 30.5394 36.0083 30.5394C32.2988 30.5394 29.2946 27.5353 29.2946 23.8257C29.2946 20.1162 32.2988 17.112 36.0083 17.112ZM13.5519 57.1203C19.1784 51.1452 27.1535 47.4191 36.0083 47.4191C44.8631 47.4191 52.8382 51.1535 58.4647 57.1203C52.8382 63.0954 44.8631 66.8216 36.0083 66.8216C27.1535 66.8216 19.1784 63.0871 13.5519 57.1203ZM61.7012 53.0373C55.1701 46.3817 46.0664 42.249 36 42.249C25.9336 42.249 16.8382 46.3817 10.2988 53.0373C7.05394 48.1577 5.16183 42.2988 5.16183 36C5.16183 18.971 18.9627 5.17012 35.9917 5.17012C53.0207 5.17012 66.8216 18.971 66.8216 36C66.8216 42.2988 64.9295 48.1577 61.6846 53.0373H61.7012ZM61.7012 61.2116C62.9544 59.9419 64.1079 58.5726 65.1535 57.1286C69.4606 51.195 72 43.8921 72 36C72 16.1162 55.8838 0 36 0C16.1162 0 0 16.1162 0 36C0 43.8921 2.53942 51.195 6.84647 57.1286C7.90041 58.5726 9.05394 59.9419 10.2988 61.2116C16.8299 67.8672 25.9336 72 36 72C46.0664 72 55.1618 67.8672 61.7012 61.2116ZM36 35.8091C42.6141 35.8091 47.9751 30.4481 47.9751 23.834C47.9751 17.2199 42.6141 11.8589 36 11.8589C29.3859 11.8589 24.0249 17.2199 24.0249 23.834C24.0249 30.4481 29.3859 35.8091 36 35.8091Z"
            fill="#fff"
          />
        </svg>
      )}
    </div>
  );
}

export default Avatar;
