import style from './style.module.css';

export default function UserCabinetFocusSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      width="22.9333mm"
      height="21.7356mm"
      version="1.1"
      viewBox="0 0 59.82 56.7"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
      className={style.svg}
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className={style.svg__fill}
          d="M52.94 56.7c3.8,0 6.88,-3.08 6.88,-6.89l0 -31.68c0,-1.88 -0.75,-3.58 -1.97,-4.83 -2.19,7.37 -6.02,20.49 -8.12,23.04 -2.33,2.85 -5.87,3.87 -9.44,3.87 -6.92,0 -13.84,0 -20.76,0 -3.57,0 -7.11,-1.02 -9.44,-3.87 -2.09,-2.55 -5.93,-15.68 -8.12,-23.04 -1.22,1.24 -1.97,2.94 -1.97,4.83l0 31.68c0,3.8 3.08,6.89 6.88,6.89l46.05 0zm-33.41 -20.89c6.92,0 13.84,0 20.76,0 2.18,0 4.58,-0.48 6.03,-2.25 1.71,-2.09 5.54,-15.46 7.54,-22.25 -0.3,-0.04 -0.6,-0.06 -0.91,-0.06l-46.05 0c-0.31,0 -0.62,0.02 -0.92,0.06 1.99,6.79 5.82,20.15 7.53,22.25 1.45,1.77 3.84,2.25 6.03,2.25zm14.6 -10.46l-8.45 0c-1.15,0 -2.09,-0.94 -2.09,-2.09 0,-1.15 0.94,-2.09 2.09,-2.09l8.45 0c1.15,0 2.09,0.94 2.09,2.09 0,1.15 -0.94,2.09 -2.09,2.09zm-15.15 -18.33l21.84 0 0 -1.02c0,-3.3 -2.7,-5.99 -5.99,-5.99l-9.86 0c-3.3,0 -5.99,2.7 -5.99,5.99l0 1.02z"
        />
      </g>
    </svg>
  );
}
