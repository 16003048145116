import style from './style.module.css';

export default function WorkshopSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      width="24.2452mm"
      height="24.3035mm"
      version="1.1"
      viewBox="0 0 83.12 83.32"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
      className={style.svg}
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className={style.svg__fill}
          d="M59.5 27.57l8.8 8.76 -32.69 32.69 -8.81 -8.76 32.7 -32.69zm-40.99 48.58l-11.42 -11.42 1.85 -9.05c0.27,-1.32 0.75,-2.59 1.4,-3.75l20.91 20.81c-1.18,0.69 -2.47,1.19 -3.81,1.48l-8.93 1.94zm53.82 -43.86l-21.56 -21.46 2.84 -2.84c1.63,-1.63 3.76,-2.44 5.89,-2.44 2.13,0 4.27,0.81 5.9,2.44l9.72 9.72c3.26,3.26 3.26,8.53 0.01,11.78l-2.79 2.79zm-25.59 -17.43l8.72 8.68 -32.7 32.69 -8.71 -8.67 32.69 -32.69zm12.76 -14.87c-3.71,0 -7.2,1.44 -9.82,4.07l-41.15 41.15c-2.56,2.56 -4.3,5.79 -5.03,9.34l-3.27 16.02c-0.64,3.14 0.15,6.36 2.17,8.83 2.03,2.48 5.02,3.91 8.2,3.91 0.76,0 1.53,-0.08 2.28,-0.25l15.76 -3.42c3.52,-0.77 6.73,-2.52 9.28,-5.07l41.15 -41.15c2.62,-2.62 4.07,-6.11 4.07,-9.83 -0,-3.71 -1.45,-7.2 -4.07,-9.82l-9.72 -9.72c-2.63,-2.62 -6.12,-4.07 -9.83,-4.07z"
        />
      </g>
    </svg>
  );
}
