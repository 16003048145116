import React from 'react';

export default function MaterialCardLogo({ styleCard }) {
  return (
    <div className={styleCard.material__logo}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        space="preserve"
        version="1.1"
        style={{
          shapeRendering: 'geometricPrecision',
          textRendering: 'geometricPrecision',
          imageRendering: 'optimizeQuality',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        }}
        viewBox="0 0 156.54 112.15"
        xlink="http://www.w3.org/1999/xlink"
        xodm="http://www.corel.com/coreldraw/odm/2003"
      >
        <g id="Слой_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer" />
          <path
            className={styleCard.logo__path}
            strokeWidth="0.55"
            strokeMiterlimit="22.9256"
            d="M69.92 105.12l-61.35 -27c-4.3,-1.89 -3.4,-5.43 1.85,-8.13l-5.2 -2.29c-0.28,-0.12 -0.55,-0.25 -0.82,-0.39 -1.62,1.33 -2.96,2.96 -3.64,4.95 -1.65,4.82 1.18,9 5.58,10.94l61.35 27c6.79,2.99 17.44,1.64 24.14,-1.31l55.4 -24.39c3.53,-1.55 7.25,-4.06 8.56,-7.88 1.37,-4 -0.34,-7.56 -3.48,-9.76 -1.26,0.84 -2.6,1.54 -3.95,2.14l-2.19 0.96 1.81 0.8c4.59,2.02 3.26,5.92 -2.97,8.66l-55.4 24.39c-6.23,2.74 -15.08,3.33 -19.67,1.31zm-63.58 -40.62l61.35 27c6.79,2.99 17.44,1.64 24.14,-1.31l55.4 -24.39c3.53,-1.55 7.25,-4.06 8.56,-7.88 1.37,-4 -0.34,-7.56 -3.48,-9.76 -1.26,0.84 -2.6,1.54 -3.95,2.14l-2.19 0.96 1.81 0.8c4.59,2.02 3.26,5.92 -2.97,8.66l-55.4 24.39c-6.23,2.74 -15.08,3.33 -19.67,1.31l-61.35 -27c-4.3,-1.89 -3.4,-5.43 1.85,-8.13l-5.2 -2.29c-0.28,-0.12 -0.55,-0.25 -0.82,-0.39 -1.62,1.33 -2.96,2.96 -3.64,4.95 -1.65,4.82 1.18,9 5.58,10.94zm0 -18.03l61.35 27c6.79,2.99 17.44,1.64 24.14,-1.31l55.4 -24.39c3.53,-1.55 7.25,-4.06 8.56,-7.88 1.65,-4.82 -1.18,-9 -5.58,-10.94l-61.35 -27c-6.79,-2.99 -17.44,-1.64 -24.14,1.31l-55.4 24.39c-3.53,1.55 -7.25,4.06 -8.56,7.88 -1.65,4.82 1.18,9 5.58,10.94zm63.58 21.93l-61.35 -27c-4.59,-2.02 -3.26,-5.92 2.97,-8.66l55.4 -24.39c6.23,-2.74 15.08,-3.33 19.67,-1.31l61.35 27c4.59,2.02 3.26,5.92 -2.97,8.66l-55.4 24.39c-6.23,2.74 -15.08,3.33 -19.67,1.31zm-5.54 -14.93c-1.68,0.77 -2.42,2.77 -1.65,4.45 0.77,1.68 2.77,2.42 4.45,1.65l54.31 -24.97c1.68,-0.77 2.42,-2.77 1.65,-4.45 -0.77,-1.68 -2.77,-2.42 -4.45,-1.65l-54.31 24.97zm-13.89 -1.05l54.31 -24.97c1.68,-0.77 2.42,-2.77 1.65,-4.45 -0.77,-1.68 -2.77,-2.42 -4.45,-1.65l-54.31 24.97c-1.68,0.77 -2.42,2.77 -1.65,4.45 0.77,1.68 2.77,2.42 4.45,1.65zm-16.01 -7.09l54.31 -24.97c1.68,-0.77 2.42,-2.77 1.65,-4.45 -0.77,-1.68 -2.77,-2.42 -4.45,-1.65l-54.31 24.97c-1.68,0.77 -2.42,2.77 -1.65,4.45 0.77,1.68 2.77,2.42 4.45,1.65z"
          />
        </g>
      </svg>
    </div>
  );
}
