import style from './style.module.css';

function SendingTitle() {
  return (
    <div className={style.title}>
      <div className={style.title__name}>форма отправки информации</div>
    </div>
  );
}

export default SendingTitle;
