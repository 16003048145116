import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import style from './style.module.css';

function NavProcessed() {
  const location = useLocation();

  const [buttonProcessedHovered, setButtonProcessedHovered] = useState(false);

  return (
    <div
      className={style.nav}
      onMouseEnter={() => setButtonProcessedHovered(true)}
      onMouseLeave={() => setButtonProcessedHovered(false)}
    >
      <NavLink
        className={style.link}
        activeClassName={style.active}
        to="/history/incoming-materials/processed"
      >
        {location.pathname === '/history/incoming-materials/processed' ||
        buttonProcessedHovered ? (
          <svg
            className={style.linkSvgMedium}
            viewBox="0 0 32 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.7092 13.655L18.7675 18.396C17.4153 18.9798 15.2776 19.252 13.9057 18.6581L2.26824 13.6156L0.950872 14.2192C-0.0732623 14.7488 -0.24222 15.4316 0.602666 15.7978L14.373 21.7661C15.2828 22.1619 17.0524 22.043 18.2954 21.5036L30.8525 16.0649C32.1006 15.5256 32.2593 14.9516 31.4591 14.3628C30.9174 13.9619 30.7183 13.8234 29.7041 13.6601L29.7092 13.655L29.7092 13.655ZM15.1165 7.44874L19.7217 4.52853C20.1709 4.245 20.9028 4.2425 21.3549 4.52353C21.8074 4.80428 21.81 5.265 21.3641 5.5482L15.9049 9.01022C15.5569 9.23044 15.0241 9.28595 14.5812 9.15183L14.5214 9.13436L11.6715 7.32742C11.2223 7.04387 11.2282 6.58348 11.6803 6.30245C12.1328 6.0214 12.8677 6.02422 13.3139 6.30744L15.1136 7.44876H15.1165V7.44874ZM13.9057 14.6743L2.21327 9.60684C1.54212 9.83963 -1.1967 10.8442 0.602666 11.621L14.373 17.5892C15.2828 17.9851 17.0524 17.8665 18.2954 17.3272L30.8525 11.8885C32.1002 11.3488 32.3736 10.5817 31.4591 10.1859C30.8327 9.91374 31.116 10.0128 29.8533 9.60684L18.7624 14.4123C17.4106 14.9961 15.2729 15.2682 13.9009 14.6743H13.9057V14.6743ZM0.602666 7.63718L14.373 13.6055C15.2828 14.0013 17.0524 13.8827 18.2954 13.3434L30.8525 7.90467C32.1002 7.365 32.3736 6.59796 31.4591 6.20213L17.6888 0.234217C16.779 -0.161951 15.0093 -0.0430188 13.7664 0.496288L1.20929 5.93501C-0.0384262 6.47431 -0.311873 7.24137 0.602666 7.63718Z"
              fill="#7D7D7D"
            />
          </svg>
        ) : (
          <svg
            className={style.linkSvgMedium}
            viewBox="0 0 33 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.3934 21.6691L1.71394 16.0883C0.824692 15.6989 1.01174 14.966 2.09724 14.408L1.02094 13.9357C0.962679 13.9112 0.907484 13.8836 0.852289 13.856C0.518054 14.132 0.239013 14.4693 0.101026 14.8802C-0.239341 15.8768 0.346337 16.7415 1.25398 17.1401L13.9335 22.7209C15.3379 23.3403 17.5395 23.0613 18.9225 22.4511L30.3723 17.4099C31.1021 17.091 31.8718 16.5698 32.1416 15.7817C32.4237 14.9538 32.0711 14.2179 31.421 13.764C31.1604 13.9388 30.8844 14.0829 30.6054 14.2056L30.1515 14.4049L30.5256 14.5705C31.4731 14.9875 31.2002 15.794 29.9124 16.3612L18.4625 21.4024C17.1746 21.9697 15.3471 22.0892 14.3965 21.6722H14.3934V21.6691ZM1.25092 13.2734L13.9304 18.8542C15.3348 19.4736 17.5365 19.1946 18.9194 18.5844L30.3692 13.5432C31.099 13.2243 31.8687 12.7031 32.1385 11.915C32.4207 11.0871 32.068 10.3511 31.4179 9.89736C31.1573 10.0721 30.8813 10.2162 30.6023 10.3389L30.1485 10.5382L30.5226 10.7038C31.4701 11.1208 31.1972 11.9273 29.9093 12.4945L18.4594 17.5357C17.1716 18.103 15.344 18.2225 14.3934 17.8055L1.71394 12.2247C0.824692 11.8353 1.01174 11.1024 2.09724 10.5443L1.02094 10.0721C0.962679 10.0476 0.907484 10.02 0.852289 9.99242C0.518054 10.2684 0.239013 10.6057 0.101026 11.0166C-0.239341 12.0131 0.346337 12.8778 1.25398 13.2765H1.25092V13.2734ZM1.25092 9.5478L13.9304 15.1286C15.3348 15.748 17.5365 15.4689 18.9194 14.8587L30.3692 9.81764C31.099 9.49874 31.8687 8.97745 32.1385 8.18939C32.4789 7.19282 31.8932 6.3281 30.9856 5.92947L18.3061 0.348667C16.9017 -0.270742 14.7001 0.00829899 13.3171 0.618508L1.86726 5.65963C1.13746 5.97853 0.367801 6.49982 0.0979602 7.28788C-0.242408 8.28445 0.34327 9.14917 1.25092 9.5478ZM14.3934 14.0799L1.71394 8.4991C0.76643 8.08207 1.03934 7.27561 2.32722 6.70833L13.7771 1.66721C15.0649 1.09993 16.8925 0.98034 17.8431 1.39736L30.5226 6.97817C31.4701 7.3952 31.1972 8.20166 29.9093 8.76894L18.4594 13.81C17.1716 14.3773 15.344 14.4969 14.3934 14.0799Z"
              fill="#7D7D7D"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.7978 8.81497L20.5445 5.27637C21.0076 4.93293 21.7619 4.92987 22.228 5.27024C22.6941 5.6106 22.6971 6.16869 22.2372 6.51212L16.6104 10.7069C16.2516 10.9737 15.7027 11.0411 15.2458 10.8786L15.1845 10.8571L12.2469 8.66778C11.7839 8.32435 11.79 7.76627 12.2561 7.4259C12.7222 7.08553 13.4796 7.0886 13.9396 7.43203L15.7947 8.81497H15.7978Z"
              fill="#7D7D7D"
            />
          </svg>
        )}
        <div className={style.nav__name}>Обработанные</div>
      </NavLink>
    </div>
  );
}

export default NavProcessed;
