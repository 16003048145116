import style from './style.module.css';

export default function ProcessedSvg() {
  return (
    <svg
      className={style.linkSvg}
      viewBox="0 0 31 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3031 15.3179L25.0863 1.04843C26.4263 -0.340329 28.6227 -0.350249 29.9774 1.02363C31.332 2.39751 31.3416 4.64929 30.0015 6.03805L13.6689 22.9512C12.6287 24.0275 11.0322 24.3052 9.7018 23.6455L9.52764 23.5612L0.998457 14.7327C-0.341635 13.3439 -0.33196 11.0921 1.02265 9.71826C2.37725 8.34438 4.57365 8.3543 5.91374 9.74306L11.3031 15.3229V15.3179ZM10.8339 21.6219C11.2596 21.7658 11.7434 21.6517 12.0627 21.3194L28.3954 4.40626C28.8598 3.92515 28.855 3.13653 28.3857 2.66534C27.9164 2.1892 27.1472 2.19416 26.6876 2.67526L11.3031 18.6063L4.3124 11.3649C3.84797 10.8838 3.07875 10.8789 2.61431 11.355C2.14503 11.8312 2.1402 12.6198 2.60463 13.0959L10.8387 21.6219H10.8339Z"
        fill="#7D7D7D"
      />
    </svg>
  );
}
