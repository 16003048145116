import style from './style.module.css';

export default function ProcessingSvg() {
  return (
    <svg
      className={style.linkSvg}
      viewBox="0 0 23 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2129 26.2901C13.3201 28.7071 9.66838 28.7071 7.77554 26.2901L0.734181 18.2644L0.710884 18.2353C-1.00723 15.9988 0.582754 12.7548 3.40745 12.7548H5.7371V2.9703C5.7371 1.3279 7.065 0 8.7074 0H14.5898C16.2322 0 17.5601 1.33372 17.5601 2.9703V12.7548H19.5752C22.3999 12.7548 23.9957 15.9988 22.2718 18.2353L22.2485 18.2644L15.2071 26.2901H15.2129ZM2.4057 16.8958L9.44124 24.9156L9.46453 24.9447C10.4954 26.2842 12.5047 26.2784 13.5356 24.9447L13.5589 24.9156L20.5944 16.8958C21.2001 16.0746 20.6177 14.9039 19.5868 14.9039H15.4226V2.96448C15.4226 2.51602 15.0557 2.1491 14.6072 2.1491H8.72487C8.27059 2.1491 7.90949 2.51602 7.90949 2.96448V14.9039H3.42492C2.39405 14.9039 1.81164 16.0687 2.41735 16.8958H2.4057Z"
        fill="#7D7D7D"
      />
    </svg>
  );
}
