import style from './style.module.css';

export default function ProcessingFocussedIcon() {
  return (
    <svg
      className={style.linkSvg}
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3742 21.2557C30.575 20.6753 30.7353 20.0869 30.8628 19.4993L28.1385 17.8747L28.2088 14.6929L31 13.1903C30.7875 11.9668 30.4334 10.7789 29.9535 9.64729L26.8077 9.96429L25.0206 7.34998L26.4108 4.46233C25.5703 3.64132 24.6317 2.90455 23.6039 2.2811L21.2291 4.40689L18.2781 3.3359L17.7527 0.163323C16.5302 -0.0148366 15.3093 -0.0462612 14.1109 0.0642614L13.4207 3.20134L10.414 4.1019L8.15424 1.8572C7.08429 2.432 6.07598 3.13216 5.15517 3.95205L6.40765 6.89764L4.50182 9.42221L1.37435 8.95856C1.10006 9.52774 0.850491 10.1215 0.637881 10.7408C0.447338 11.3073 0.289893 11.8742 0.160423 12.4435L2.88257 14.0769L2.80224 17.2585L0 18.7507C0.214178 19.9756 0.561291 21.1722 1.04066 22.3064L4.18839 21.9998L5.9681 24.6194L4.56543 27.503C5.43235 28.3517 6.3973 29.1126 7.45778 29.7523L9.82728 27.6172L10.316 27.7923V23.1865C8.9757 22.1899 7.8978 20.795 7.27713 19.0884C5.60789 14.4667 7.92059 9.34502 12.4652 7.64649C17.0022 5.92514 22.0501 8.2815 23.7288 12.8964C25.2074 16.9407 23.6144 21.366 20.1573 23.5549V28.0262L20.6417 27.8787L22.9072 30.117C23.9797 29.5362 24.9868 28.8338 25.9029 28.0133L24.6352 25.0715L26.5339 22.5344L29.6652 22.9942C29.9304 22.4332 30.1713 21.8541 30.3742 21.2557V21.2557ZM11.2154 28.1144L12.7826 28.6756L13.3144 31.8465C14.5381 32.0192 15.7594 32.0442 16.9581 31.9313L17.6392 28.7926L19.2579 28.2999V24.052C19.0274 24.1624 18.7908 24.2641 18.5473 24.3554C16.0425 25.2971 13.3806 25.0044 11.2154 23.7735V28.1144V28.1144ZM14.129 14.344C14.8783 14.6068 15.6158 14.6068 16.3653 14.3445L15.5833 11.9941C15.5078 11.7676 15.3073 11.7499 15.2476 11.7499C15.1879 11.7498 14.987 11.7686 14.9115 11.9943L14.129 14.344L14.129 14.344ZM19.2491 23.0123L16.6436 15.1812C15.715 15.5043 14.7788 15.5041 13.8503 15.1807L11.3163 22.7902C13.463 23.9735 16.0813 24.2428 18.5473 23.3157C18.7838 23.227 19.0138 23.1285 19.238 23.0218L19.2491 23.0123V23.0123Z"
        fill="#8A8A8A"
      />
    </svg>
  );
}
