import style from './style.module.css';

export default function BlockedIcon() {
  return (
    <svg
      className={style.linkSvgMedium}
      viewBox="0 0 33 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3483 21.6025L1.70896 16.0406C0.822626 15.6518 1.00714 14.9204 2.09117 14.3668L1.02032 13.8956C0.964307 13.8693 0.908293 13.8429 0.85228 13.8166C0.519493 14.09 0.24272 14.4261 0.101039 14.838C-0.238337 15.8298 0.344863 16.693 1.25096 17.0917L13.8903 22.6535C15.2906 23.2697 17.485 22.9929 18.8656 22.3834L30.2792 17.3586C31.0074 17.039 31.7718 16.5217 32.042 15.7342C32.3253 14.9105 31.9728 14.1757 31.3237 13.7243C31.0634 13.8989 30.7866 14.0406 30.5098 14.1658L30.0584 14.3635L30.4307 14.5283C31.3764 14.9434 31.1029 15.7474 29.8179 16.3141L18.4043 21.3389C17.1193 21.9023 15.2972 22.0242 14.3516 21.609H14.3483V21.6025ZM1.24767 13.2334L13.887 18.7952C15.2873 19.4113 17.4817 19.1346 18.8623 18.525L30.2759 13.5003C31.0041 13.1807 31.7685 12.6634 32.0387 11.8759C32.322 11.0521 31.9695 10.3174 31.3204 9.86597C31.0601 10.0406 30.7833 10.1823 30.5065 10.3075L30.0551 10.5052L30.4275 10.6699C31.3731 11.0851 31.0996 11.889 29.8146 12.4558L18.401 17.4805C17.116 18.0439 15.2939 18.1659 14.3483 17.7507L1.70896 12.1889C0.822626 11.8001 1.00714 11.0686 2.09117 10.5151L1.02032 10.0439C0.961012 10.0175 0.908293 9.99117 0.85228 9.96481C0.519493 10.2383 0.24272 10.5744 0.101039 10.9862C-0.238337 11.978 0.344863 12.8413 1.25096 13.24H1.24767V13.2334ZM1.24767 9.52L13.887 15.0818C15.2873 15.698 17.4817 15.4212 18.8623 14.8116L30.2759 9.78689C31.0041 9.46728 31.7685 8.94998 32.0387 8.16249C32.378 7.17072 31.7948 6.30746 30.8887 5.90877L18.2494 0.346952C16.8491 -0.269198 14.6547 0.00757509 13.2741 0.617135L1.86052 5.64188C1.13235 5.96149 0.367927 6.47879 0.097744 7.26628C-0.241632 8.25805 0.341568 9.12131 1.24767 9.52ZM14.3483 14.0373L1.70896 8.47551C0.763317 8.06035 1.0368 7.25639 2.32181 6.68967L13.7354 1.66492C15.0204 1.10149 16.8425 0.979576 17.7882 1.39473L30.4275 6.95655C31.3731 7.37171 31.0996 8.17567 29.8146 8.7424L18.401 13.7671C17.116 14.3306 15.2939 14.4525 14.3483 14.0373Z"
        fill="#7D7D7D"
      />
      <path
        d="M12.5755 8.63695L14.4667 7.36841L12.5755 6.09987C12.1636 5.82309 12.1636 5.37498 12.5755 5.09821C12.9873 4.82144 13.6595 4.82144 14.0713 5.09821L15.9626 6.36675L17.8539 5.09821C18.2658 4.82144 18.9379 4.82144 19.3498 5.09821C19.7617 5.37498 19.7617 5.82309 19.3498 6.09987L17.4585 7.36841L19.3498 8.63695C19.7617 8.91372 19.7617 9.36183 19.3498 9.63861C18.9379 9.91538 18.2658 9.91538 17.8539 9.63861L15.9626 8.37006L14.0713 9.63861C13.6595 9.91538 12.9873 9.91538 12.5755 9.63861C12.1636 9.36183 12.1636 8.91372 12.5755 8.63695Z"
        fill="#7D7D7D"
      />
    </svg>
  );
}
