import style from './style.module.css';

export default function PhotoFocusedSvg({ color, styleName }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      className={style[styleName]}
      version="1.1"
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      viewBox="0 0 60.72 52.33"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          fill="none"
          fillRule="nonzero"
          stroke={color}
          strokeWidth="0.18"
          strokeMiterlimit="22.9256"
          d="M36.51 2.1c-0.8,0.06 -1.43,0.73 -1.43,1.54 0,0.86 0.69,1.55 1.55,1.55 0.1,0 0.2,-0.01 0.3,-0.03l1.12 0c0.04,0 0.09,0 0.13,0 0.96,0.07 1.7,0.87 1.7,1.83l0 1.06c-0.03,0.12 -0.05,0.25 -0.05,0.38 0,0.86 0.69,1.55 1.55,1.55 0.82,0 1.5,-0.64 1.55,-1.45l0.02 0 0 -1.53c0,-2.57 -1.99,-4.7 -4.55,-4.89 -0.12,-0.01 -0.24,-0.01 -0.35,-0.01l-1.41 0 -0.12 0 0 0z"
        />
        <path
          fill="none"
          fillRule="nonzero"
          stroke={color}
          strokeWidth="0.18"
          strokeMiterlimit="22.9256"
          d="M50.68 10.77c-0.8,0.06 -1.43,0.73 -1.43,1.54 0,0.86 0.69,1.55 1.55,1.55 0.1,0 0.2,-0.01 0.3,-0.03l1.12 0c0.04,0 0.09,0 0.13,0 0.96,0.07 1.7,0.87 1.7,1.83l0 1.06c-0.03,0.12 -0.05,0.25 -0.05,0.38 0,0.86 0.69,1.55 1.55,1.55 0.82,0 1.5,-0.64 1.55,-1.45l0.02 0 0 -1.53c0,-2.57 -1.99,-4.7 -4.55,-4.89 -0.12,-0.01 -0.24,-0.01 -0.35,-0.01l-1.41 0 -0.12 0 0 0z"
        />
        <path
          fill={color}
          fillRule="nonzero"
          d="M11.29 28.78c0,-10.53 8.54,-19.07 19.07,-19.07 10.53,0 19.07,8.54 19.07,19.07 0,10.53 -8.54,19.07 -19.07,19.07 -10.53,0 -19.07,-8.54 -19.07,-19.07zm39.51 -14.92c-0.86,0 -1.55,-0.69 -1.55,-1.55 0,-0.81 0.63,-1.48 1.43,-1.54l0 -0 0.12 0 1.41 0c0.12,0 0.24,0 0.35,0.01 2.56,0.18 4.55,2.32 4.55,4.89l0 1.53 -0.02 0c-0.05,0.81 -0.72,1.45 -1.55,1.45 -0.86,0 -1.55,-0.69 -1.55,-1.55 0,-0.13 0.02,-0.26 0.05,-0.38l0 -1.06c0,-0.96 -0.74,-1.76 -1.7,-1.83 -0.04,-0 -0.09,-0 -0.13,-0l-1.12 0c-0.1,0.02 -0.19,0.03 -0.3,0.03zm-10.42 -13.86l-20.05 0c-3.03,0 -5.5,2.46 -5.5,5.5l0 1.92 -9.34 0c-3.03,0 -5.5,2.46 -5.5,5.5l0 33.92c0,3.04 2.46,5.5 5.5,5.5l49.73 0c3.03,0 5.5,-2.46 5.5,-5.5l0 -33.92c0,-3.04 -2.46,-5.5 -5.5,-5.5l-9.34 0 0 -1.92c0,-3.04 -2.46,-5.5 -5.5,-5.5zm-5.29 3.64c0,-0.81 0.63,-1.48 1.43,-1.54l0 -0 0.12 0 1.41 0c0.12,0 0.24,0 0.35,0.01 2.56,0.18 4.55,2.32 4.55,4.89l0 1.53 -0.02 0c-0.05,0.81 -0.72,1.45 -1.55,1.45 -0.86,0 -1.55,-0.69 -1.55,-1.55 0,-0.13 0.02,-0.26 0.05,-0.38l0 -1.06c0,-0.96 -0.74,-1.76 -1.7,-1.83 -0.04,-0 -0.09,-0 -0.13,-0l-1.12 0c-0.1,0.02 -0.19,0.03 -0.3,0.03 -0.86,0 -1.55,-0.69 -1.55,-1.55zm-4.73 9.37c-8.7,0 -15.76,7.06 -15.76,15.76 0,8.7 7.06,15.76 15.76,15.76 8.71,0 15.76,-7.06 15.76,-15.76 0,-8.71 -7.06,-15.76 -15.76,-15.76zm0 5.27c5.79,0 10.49,4.69 10.49,10.49 0,5.79 -4.7,10.49 -10.49,10.49 -5.79,0 -10.49,-4.7 -10.49,-10.49 0,-5.79 4.7,-10.49 10.49,-10.49z"
        />
      </g>
    </svg>
  );
}
