import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { AffiliationContext } from '../AffiliationContainer/context';

import style from './style.module.css';

function DeleteBtn() {
  const { setDeleteTag } = useContext(AffiliationContext);

  const files = useSelector((state) => state.application.changeFiles.files);

  const handleDeleteFileOpen = () => {
    setDeleteTag(true);
  };

  const [buttonDeleteHovered, setButtonDeleteHovered] = useState(false);

  if (files.type === 'text') return null;

  return (
    <div
      onMouseEnter={() => setButtonDeleteHovered(true)}
      onMouseLeave={() => setButtonDeleteHovered(false)}
      className={`${style.dialog__button}`}
      onClick={handleDeleteFileOpen}
    >
      {buttonDeleteHovered ? (
        <svg viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.29848 0H7.1864H5.81105C4.4281 0 3.2978 1.1322 3.2978 2.51325V3.22182H4.79854V2.55504V2.51325C4.79854 2.12192 5.02459 1.77808 5.35133 1.61091C5.49001 1.54062 5.64578 1.49883 5.81295 1.49883H7.1883H7.30038H8.40219C8.67384 1.49883 8.92269 1.60711 9.10506 1.78378C9.19814 1.87306 9.27223 1.97754 9.32732 2.09532C9.38621 2.2226 9.4185 2.36507 9.4185 2.51325V3.22182H10.9192V2.51325C10.9192 1.1303 9.78703 0 8.40598 0H7.30418H7.29848ZM0 20.4859C0 21.6732 0.965028 22.6382 2.15421 22.6382H12.0571C13.2463 22.6382 14.2113 21.6732 14.2113 20.4859V4.4509H0.00190151V20.4859H0ZM7.89877 7.89307V19.2036C7.89877 19.6501 7.53404 20.0148 7.08762 20.0148C6.6412 20.0148 6.27647 19.6501 6.27647 19.2036V7.89307C6.27647 7.44666 6.6412 7.08192 7.08762 7.08192C7.53404 7.08192 7.89877 7.44666 7.89877 7.89307ZM3.94179 7.89307V19.2036C3.94179 19.6501 3.57706 20.0148 3.13064 20.0148C2.68422 20.0148 2.31948 19.6501 2.31948 19.2036V7.89307C2.31948 7.44666 2.68422 7.08192 3.13064 7.08192C3.57706 7.08192 3.94179 7.44666 3.94179 7.89307ZM11.0104 20.0148C10.564 20.0148 10.1993 19.6501 10.1993 19.2036V7.89307C10.1993 7.44666 10.564 7.08192 11.0104 7.08192C11.4568 7.08192 11.8216 7.44666 11.8216 7.89307V19.2036C11.8216 19.6501 11.4568 20.0148 11.0104 20.0148Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.29624 16.9426C1.29624 18.629 2.69436 20 4.41223 20H12.5831C14.3009 20 15.6991 18.629 15.6991 16.9426V3.95445V3.92368H16.3307C16.6991 3.92368 17 3.62825 17 3.26666C17 2.90506 16.6991 2.60963 16.3307 2.60963H12.6771V2.0357C12.6771 0.915525 11.7429 0 10.6034 0H6.38715C5.24608 0 4.31348 0.917064 4.31348 2.0357V2.60963H0.669279C0.30094 2.60963 0 2.90506 0 3.26666C0 3.62825 0.30094 3.92368 0.669279 3.92368H1.30094V3.95445V16.9426H1.29624ZM12.5831 18.6859H4.41223C3.43103 18.6859 2.6348 17.9043 2.6348 16.9426V3.95445H14.3589V16.9426C14.3589 17.9043 13.5627 18.6859 12.5815 18.6859H12.5831ZM11.4373 2.60963H5.54859V2.06955V2.0357C5.54859 1.71873 5.73511 1.44022 6.0047 1.30482C6.11912 1.24788 6.24765 1.21403 6.38558 1.21403H10.6019C10.826 1.21403 11.0313 1.30174 11.1818 1.44484C11.2586 1.51716 11.3197 1.60178 11.3652 1.69718C11.4138 1.80028 11.4404 1.91568 11.4404 2.0357V2.60963H11.4373ZM4.54859 6.74258V15.904C4.54859 16.2656 4.84953 16.561 5.21787 16.561C5.58621 16.561 5.88715 16.2656 5.88715 15.904V6.74258C5.88715 6.38098 5.58621 6.08555 5.21787 6.08555C4.84953 6.08555 4.54859 6.38098 4.54859 6.74258ZM7.81348 6.74258V15.904C7.81348 16.2656 8.11442 16.561 8.48276 16.561C8.8511 16.561 9.15204 16.2656 9.15204 15.904V6.74258C9.15204 6.38098 8.8511 6.08555 8.48276 6.08555C8.11442 6.08555 7.81348 6.38098 7.81348 6.74258ZM11.0502 6.74258V15.904C11.0502 16.2656 11.3511 16.561 11.7194 16.561C12.0878 16.561 12.3887 16.2656 12.3887 15.904V6.74258C12.3887 6.38098 12.0878 6.08555 11.7194 6.08555C11.3511 6.08555 11.0502 6.38098 11.0502 6.74258Z"
            fill="white"
          />
        </svg>
      )}
      <p>В корзину</p>
    </div>
  );
}

export default DeleteBtn;
