import style from './style.module.css';

export default function ProcessedFocusedIcon() {
  return (
    <svg
      className={style.linkSvgMedium}
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7092 13.655L18.7675 18.396C17.4153 18.9798 15.2776 19.252 13.9057 18.6581L2.26824 13.6156L0.950872 14.2192C-0.0732623 14.7488 -0.24222 15.4316 0.602666 15.7978L14.373 21.7661C15.2828 22.1619 17.0524 22.043 18.2954 21.5036L30.8525 16.0649C32.1006 15.5256 32.2593 14.9516 31.4591 14.3628C30.9174 13.9619 30.7183 13.8234 29.7041 13.6601L29.7092 13.655L29.7092 13.655ZM15.1165 7.44874L19.7217 4.52853C20.1709 4.245 20.9028 4.2425 21.3549 4.52353C21.8074 4.80428 21.81 5.265 21.3641 5.5482L15.9049 9.01022C15.5569 9.23044 15.0241 9.28595 14.5812 9.15183L14.5214 9.13436L11.6715 7.32742C11.2223 7.04387 11.2282 6.58348 11.6803 6.30245C12.1328 6.0214 12.8677 6.02422 13.3139 6.30744L15.1136 7.44876H15.1165V7.44874ZM13.9057 14.6743L2.21327 9.60684C1.54212 9.83963 -1.1967 10.8442 0.602666 11.621L14.373 17.5892C15.2828 17.9851 17.0524 17.8665 18.2954 17.3272L30.8525 11.8885C32.1002 11.3488 32.3736 10.5817 31.4591 10.1859C30.8327 9.91374 31.116 10.0128 29.8533 9.60684L18.7624 14.4123C17.4106 14.9961 15.2729 15.2682 13.9009 14.6743H13.9057V14.6743ZM0.602666 7.63718L14.373 13.6055C15.2828 14.0013 17.0524 13.8827 18.2954 13.3434L30.8525 7.90467C32.1002 7.365 32.3736 6.59796 31.4591 6.20213L17.6888 0.234217C16.779 -0.161951 15.0093 -0.0430188 13.7664 0.496288L1.20929 5.93501C-0.0384262 6.47431 -0.311873 7.24137 0.602666 7.63718Z"
        fill="#7D7D7D"
      />
    </svg>
  );
}
