import style from './style.module.css';

export default function UserCabinetSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      width="26.1789mm"
      height="25.1986mm"
      version="1.1"
      viewBox="0 0 176.43 169.82"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
      className={style.svg}
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className={style.svg__fill}
          d="M147.71 30.52l-17.49 0c-0.58,-16.89 -14.57,-30.52 -31.6,-30.52l-20.81 0c-17.02,0 -31.01,13.63 -31.6,30.52l-17.49 0c-15.84,0 -28.72,12.89 -28.72,28.73l0 81.84c0,15.84 12.89,28.73 28.72,28.73l118.99 0c15.84,0 28.72,-12.89 28.72,-28.73l0 -81.84c0,-15.84 -12.88,-28.73 -28.72,-28.73zm-31.28 0l-56.44 0 0 -2.64c0,-8.51 6.96,-15.48 15.48,-15.48l25.48 0c8.51,0 15.48,6.96 15.48,15.48l0 2.64zm-100.39 16.27c5.66,19.03 15.57,52.94 20.97,59.54 6.03,7.36 15.16,10 24.39,10 17.88,0 35.76,0 53.64,0 9.23,0 18.36,-2.63 24.39,-10 5.41,-6.6 15.31,-40.51 20.97,-59.54 3.15,3.21 5.1,7.61 5.1,12.47l0 81.84c0,9.83 -7.97,17.79 -17.78,17.79l-118.99 0c-9.82,0 -17.79,-7.96 -17.79,-17.79l0 -81.84c0,-4.86 1.95,-9.26 5.1,-12.47zm134.04 -5.17c-5.15,17.53 -15.05,52.07 -19.47,57.48 -3.75,4.58 -9.93,5.82 -15.57,5.82 -17.88,0 -35.76,0 -53.64,0 -5.64,0 -11.82,-1.24 -15.57,-5.82 -4.42,-5.41 -14.32,-39.95 -19.47,-57.48 0.77,-0.1 1.56,-0.16 2.36,-0.16l118.99 0c0.8,0 1.59,0.05 2.36,0.16zm-72.77 36.27l21.82 0c2.97,0 5.4,-2.43 5.4,-5.4l0 -0c0,-2.97 -2.43,-5.4 -5.4,-5.4l-21.82 0c-2.97,0 -5.4,2.43 -5.4,5.4l0 0c0,2.97 2.43,5.4 5.4,5.4z"
        />
      </g>
    </svg>
  );
}
