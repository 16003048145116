import { useDispatch, useSelector } from 'react-redux';
import { changeBookmarkMaterial } from '../../../../../../redux/actions/historianSendMaterial';

import style from './style.module.css';

function IsMaterialButtons() {
  const dispatch = useDispatch();

  const bookmark = useSelector(
    (state) => state.historianSendMaterial.materials.bookmark,
  );

  const handleChangeBookmark = () => {
    dispatch(changeBookmarkMaterial());
  };

  return (
    <div className={style.headerButtons}>
      <button className={style.headerBtn}>
        <svg viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.7462 1.12557V10.3851C17.099 10.4154 17.4443 10.4716 17.7798 10.5529V1.12557C17.7798 0.506989 17.2679 0 16.6416 0H3.31442L0 3.43511V21.8744C0 22.493 0.511923 23 1.13819 23H12.5754C12.0963 22.7125 11.6554 22.3689 11.2623 21.9779H1.13819C1.08289 21.9779 1.03356 21.9291 1.03356 21.8744V3.84454L3.75684 1.02211H16.6416C16.6969 1.02211 16.7462 1.07088 16.7462 1.12557ZM9.2632 16.8311H4.37564C4.13574 16.8311 3.93994 17.0558 3.93994 17.3307C3.93994 17.6057 4.13574 17.8303 4.37564 17.8303H9.28636C9.26544 17.6138 9.25423 17.3943 9.25423 17.1726C9.25423 17.058 9.25722 16.945 9.2632 16.8319V16.8311ZM4.37564 13.9437H10.0763C10.2684 13.5911 10.4918 13.2571 10.7414 12.9445H4.37564C4.13574 12.9445 3.93994 13.1691 3.93994 13.4441C3.93994 13.719 4.13574 13.9437 4.37564 13.9437ZM4.37564 10.057H13.4034C13.6433 10.057 13.8391 9.83233 13.8391 9.55741C13.8391 9.28248 13.6433 9.05781 13.4034 9.05781H4.37564C4.13574 9.05781 3.93994 9.28248 3.93994 9.55741C3.93994 9.83233 4.13574 10.057 4.37564 10.057ZM4.37564 6.17033H13.4034C13.6433 6.17033 13.8391 5.94566 13.8391 5.67074C13.8391 5.39581 13.6433 5.17114 13.4034 5.17114H4.37564C4.13574 5.17114 3.93994 5.39581 3.93994 5.67074C3.93994 5.94566 4.13574 6.17033 4.37564 6.17033ZM16.1439 11.3814C12.9094 11.3814 10.2878 13.974 10.2878 17.1726C10.2878 20.3712 12.9094 22.9638 16.1439 22.9638C19.3784 22.9638 22 20.3712 22 17.1726C22 13.974 19.3784 11.3814 16.1439 11.3814ZM16.1439 12.4035C18.8074 12.4035 20.9664 14.5386 20.9664 17.1726C20.9664 19.8066 18.8074 21.9417 16.1439 21.9417C13.4804 21.9417 11.3214 19.8066 11.3214 17.1726C11.3214 14.5386 13.4804 12.4035 16.1439 12.4035ZM15.5064 17.6589L17.5997 15.5681C17.803 15.3649 18.137 15.3634 18.3426 15.5644C18.5481 15.7654 18.5496 16.0958 18.3463 16.299L15.8651 18.7771C15.7075 18.9345 15.4646 18.9751 15.2628 18.8791L15.2359 18.8665L13.9408 17.5724C13.7375 17.3692 13.739 17.0388 13.9445 16.8378C14.15 16.6368 14.4841 16.6383 14.6873 16.8415L15.5057 17.6589H15.5064Z"
            fill="white"
          />
        </svg>

        <p>Добавить в мою статью</p>
      </button>
      <button className={style.headerBtn} onClick={handleChangeBookmark}>
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 477.75 476.29"
        >
          <path
            d="M541.35,768c-53.24,0-106.48.13-159.72,0-35.12-.11-45.67-10.24-45.72-44.49q-.26-161.07,0-322.15c.06-33.16,10.65-43.88,43.47-44,84.82-.21,169.65-.56,254.46.53C645,358,662.92,361.43,674,365c59,19,74,71,73.91,193.15C750,617,746.8,669.88,746.48,725.82c-.18,30.95-11.35,41.91-42.7,42.08C649.64,768.19,595.5,768,541.35,768ZM422.48,378.15c-12.43,0-20.52,0-28.61,0-37.1-.08-37.12-.08-37.13,36.7q0,138,0,276c0,61.27,0,61.28,60.86,55.23.71-.07,1.34-1.06,4.89-4.05,0-36-.17-74.58,0-113.11.19-33.28,10.6-44,43.21-44.2q74.41-.4,148.83,0c34.81.13,45.13,10.36,45.32,44.89.21,38.47.05,76.95.05,117.5h29.83c35.85,0,35.89,0,35.9-35.26,0-68.55,2.49-137.26,1.46-205.53C726,431,701,387,659.94,380.36c0,35.07.23,77.79-.07,106.5-.32,31.42-11.09,42.59-42,42.79q-75.76.51-151.53,0c-33-.16-43.59-10.74-43.83-43.63C422.28,451.08,422.48,416.11,422.48,378.15ZM443.42,745.2H639c0-41.4.9-81-.87-120.39-.3-6.67-11.92-18.17-18.57-18.36q-78.31-2.3-156.72,0c-6.65.19-18.26,11.69-18.55,18.35C442.52,664.24,443.42,703.8,443.42,745.2Zm0-365.94c0,39-1,75,.9,110.83.34,6.56,12.2,17.71,19,17.91,52.22,1.5,104.5,1.3,156.73.18,6.34-.14,17.72-8.65,17.92-13.62,1.51-38.28.83-76.64.83-115.3Z"
            transform="translate(-270.87 -291.76)"
          />
          <path
            d="M315.82,702.75c-32.46.13-44.9-12.82-44.91-38.12Q270.85,497,271,329.37c0-24.08,12.59-36.92,36.73-37,89.22-.2,178.52-2,267.6,1.5,18.15.71,35.49,21.82,53.2,33.52-1.53,2.9-5.46,8.19-7,11.09-8.9-2.15-29.66-25.43-48.51-25.4-75.7.15-253.66-.35-253.66-.35s-28.34-3.76-28,27.75c1.1,103.63.3,207.28.6,310.92,0,12.42-5.38,28.18,15.56,31.83C310.66,683.82,312.18,693.8,315.82,702.75Z"
            transform="translate(-270.87 -291.76)"
          />
          <path
            d="M575.83,401.22H604V485H575.83Z"
            transform="translate(-270.87 -291.76)"
          />
        </svg>
        {bookmark ? <p>Удалить из кабинета</p> : <p>Сохранить в кабинет</p>}
      </button>
    </div>
  );
}

export default IsMaterialButtons;
