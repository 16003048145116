import { useState, useCallback, useContext } from 'react';
import { AffiliationContext } from '../AffiliationContainer/context';

import style from './style.module.css';

function BookmarkBtn() {
  const { bookmark, setBookmark } = useContext(AffiliationContext);

  const handleChangeBookmark = useCallback(() => {
    setBookmark(!bookmark);
  }, [bookmark, setBookmark]);

  const [buttonCabinetHovered, setButtonCabinetHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setButtonCabinetHovered(true)}
      onMouseLeave={() => setButtonCabinetHovered(false)}
      className={`${style.dialog__button}`}
      onClick={handleChangeBookmark}
    >
      {buttonCabinetHovered ? (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 19.5313H2.78479C2.78479 16.7466 2.78479 13.9618 2.78479 11.177C2.78479 10.3648 2.74343 9.8698 3.48036 9.77455C3.86387 9.72442 12.8123 9.76578 13.924 9.76578C16.4506 9.76578 16.7463 9.4487 16.7463 11.1394C16.7463 13.9367 16.7463 16.734 16.7463 19.5313H19.5311V4.47944C19.5236 4.42931 19.4998 4.408 19.4284 4.33782L18.7967 3.71118C18.4195 3.33394 15.201 0.0503428 15.0582 0.0102378L12.5504 0.00146484L12.5516 3.36026C12.5529 3.73123 12.4188 3.99442 12.1556 4.10847C11.8673 4.23379 8.31928 4.15985 7.66757 4.15985C7.03091 4.15985 3.48287 4.23379 3.2084 4.11724C2.63064 3.87285 2.78479 2.94417 2.78479 2.28996C2.78479 1.52671 2.78479 0.764713 2.78479 0.00146484H0V19.5326V19.5313Z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.1582 11.2532V19.5311H15.3738V11.2532C15.3738 11.1655 15.3475 11.1392 15.2598 11.1392H4.2735C4.18578 11.1392 4.15946 11.1655 4.15946 11.2532H4.1582Z"
            fill="#fff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.1582 2.78479H11.1766V0H4.1582V2.78479Z"
            fill="#fff"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.51483 0.00423658H10.6081V0L11.8528 0.00423658H11.9386L14.1451 0.0127097L14.3051 0.0571938C14.7182 0.171581 15.0445 0.533809 15.3432 0.824015C15.6854 1.15765 16.0244 1.49657 16.3612 1.83656C16.9121 2.38943 17.4566 2.95184 18.0106 3.50047L18.5445 4.03005C18.7839 4.26729 18.9354 4.5109 18.9862 4.85088L19 4.94197V19H0V0.00317743H3.51483V0.00423658ZM1.24576 17.7555H3.59958C3.59958 15.4021 3.59958 13.0487 3.59958 10.6952C3.59958 10.0089 3.56462 9.59056 4.1875 9.51006C4.51165 9.4677 12.0752 9.50265 13.0148 9.50265C15.1504 9.50265 15.4004 9.23468 15.4004 10.6635C15.4004 13.0275 15.4004 15.3915 15.4004 17.7555H17.7542V5.03517C17.7479 4.99281 17.7278 4.9748 17.6674 4.91549L17.1335 4.38592C16.8146 4.06712 14.0943 1.29216 13.9735 1.25826L11.8538 1.25085L11.8549 4.08936C11.8559 4.40287 11.7426 4.62529 11.5201 4.72167C11.2765 4.82758 8.27754 4.76509 7.7267 4.76509C7.18856 4.76509 4.18962 4.82758 3.95763 4.72908C3.46928 4.52255 3.59958 3.73772 3.59958 3.18485C3.59958 2.53983 3.59958 1.89587 3.59958 1.25085H1.24576V17.7566V17.7555ZM4.76059 10.7599V17.7555H14.2405V10.7599C14.2405 10.6857 14.2182 10.6635 14.1441 10.6635H4.85805C4.7839 10.6635 4.76165 10.6857 4.76165 10.7599H4.76059ZM4.76059 3.60321H10.6939V1.24979H4.76059V3.60321Z"
            fill="white"
          />
        </svg>
      )}
      <p>{bookmark ? 'Удалить из кабинета' : 'Сохранить в кабинет'}</p>
    </div>
  );
}

export default BookmarkBtn;
