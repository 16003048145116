import style from './style.module.css';

export default function ApproverFocusSvg() {
  return (
    <svg
      className={style.svg}
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      width="17.1208mm"
      height="26.5712mm"
      version="1.1"
      viewBox="0 0 45.59 70.75"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className={style.svg__fill}
          d="M33.73 18.57l-22.76 0 0 -5.61 -3.11 0 -1.33 0 -1.33 0c-2.86,0 -5.19,2.34 -5.19,5.19l0 47.4c0,2.86 2.34,5.19 5.19,5.19l35.2 0c2.86,0 5.19,-2.34 5.19,-5.19l0 -47.4c0,-2.86 -2.34,-5.19 -5.19,-5.19l-3.56 0 -3.11 0 0 5.61zm-22.89 17.12c0.87,-0.86 2.28,-0.85 3.14,0.02l5.89 5.95 13.88 -14.02c0.86,-0.87 2.27,-0.87 3.14,-0.02 0.87,0.86 0.87,2.27 0.02,3.14l-13.91 14.05 -1.63 1.65c-0.68,0.69 -1.71,0.83 -2.54,0.43l-1.04 -1.05 -6.95 -7.02c-0.86,-0.87 -0.85,-2.28 0.02,-3.14zm0.91 17.81l22.09 0c1.22,0 2.22,1 2.22,2.22 0,1.22 -1,2.22 -2.22,2.22l-22.09 0c-1.22,0 -2.22,-1 -2.22,-2.22 0,-1.22 1,-2.22 2.22,-2.22zm0 8.14l22.09 0c1.22,0 2.22,1 2.22,2.22 0,1.22 -1,2.22 -2.22,2.22l-22.09 0c-1.22,0 -2.22,-1 -2.22,-2.22 0,-1.22 1,-2.22 2.22,-2.22zm3.26 -47.14l15.06 0 0 -4.83c0,-0.92 -0.75,-1.67 -1.67,-1.67l-11.72 0c-0.92,0 -1.68,0.75 -1.68,1.68l0 4.82zm3.47 -10.18l8.12 0c0.01,-0.08 0.01,-0.17 0.01,-0.25 0,-2.25 -1.82,-4.07 -4.07,-4.07 -2.25,0 -4.07,1.82 -4.07,4.07 0,0.08 0,0.17 0.01,0.25z"
        />
      </g>
    </svg>
  );
}
