import style from './style.module.css';

export default function ProfileFocusSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      width="20.401mm"
      height="20.401mm"
      version="1.1"
      viewBox="0 0 43.72 43.72"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
      className={style.svg}
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className={style.svg__fill}
          d="M21.86 21.72c-4.69,0 -8.49,-3.8 -8.49,-8.49 0,-4.69 3.8,-8.49 8.49,-8.49 4.69,0 8.49,3.8 8.49,8.49 0,4.69 -3.8,8.49 -8.49,8.49zm18.22 12.22c2.3,-3.46 3.64,-7.61 3.64,-12.08 0,-12.07 -9.79,-21.86 -21.86,-21.86 -12.07,0 -21.86,9.79 -21.86,21.86 0,4.47 1.34,8.62 3.64,12.08 4.63,-4.72 11.08,-7.65 18.22,-7.65 7.14,0 13.59,2.93 18.22,7.65zm-22.98 -20.71c0,2.63 2.13,4.76 4.76,4.76 2.63,0 4.76,-2.13 4.76,-4.76 0,-2.63 -2.13,-4.76 -4.76,-4.76 -2.63,0 -4.76,2.13 -4.76,4.76zm4.76 16.72c-6.28,0 -11.93,2.64 -15.92,6.88 3.99,4.24 9.64,6.88 15.92,6.88 6.28,0 11.93,-2.64 15.92,-6.88 -3.99,-4.24 -9.64,-6.88 -15.92,-6.88z"
        />
      </g>
    </svg>
  );
}
