import styles from './startPage.module.css';

function StartPage() {
  return (
    <div className={styles['start-page']}>
      <h3>Список файлов пуст</h3>
    </div>
  );
}

export default StartPage;
