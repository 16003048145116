import style from './style.module.css';

export default function HistorianMaterialFocusSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      width="22.5397mm"
      height="28.3942mm"
      version="1.1"
      viewBox="0 0 53.3 67.14"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
      className={style.svg}
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className={style.svg__fill}
          d="M42.58 22.32c0,2.35 1.91,4.26 4.26,4.26l4.72 0 -4.86 -4.86 -4.11 -4.11 0 4.72zm-17.08 10.2l19.62 0c1.08,0 1.97,0.89 1.97,1.97 0,1.08 -0.89,1.97 -1.97,1.97l-19.62 0c-1.08,0 -1.97,-0.89 -1.97,-1.97 0,-1.08 0.89,-1.97 1.97,-1.97zm0 7.48l19.62 0c1.08,0 1.97,0.89 1.97,1.97 0,1.08 -0.89,1.97 -1.97,1.97l-19.62 0c-1.08,0 -1.97,-0.89 -1.97,-1.97 0,-1.08 0.89,-1.97 1.97,-1.97zm-8.26 -19.59l0 42.43c0,2.37 1.92,4.29 4.29,4.29l27.47 0c2.37,0 4.29,-1.92 4.29,-4.29l0 -32.85c-0.53,0.27 -1.12,0.42 -1.74,0.42l-4.72 0c-4.46,0 -8.09,-3.63 -8.09,-8.09l0 -4.72c0,-0.52 0.1,-1.01 0.29,-1.47l-17.5 0c-2.37,0 -4.29,1.92 -4.29,4.29zm15.95 2.55l-7.77 0c-1.08,0 -1.97,-0.89 -1.97,-1.97 0,-1.08 0.89,-1.97 1.97,-1.97l7.77 0c1.08,0 1.97,0.89 1.97,1.97 0,1.08 -0.89,1.97 -1.97,1.97zm-7.69 24.27l19.62 0c1.08,0 1.97,0.89 1.97,1.97 0,1.08 -0.89,1.97 -1.97,1.97l-19.62 0c-1.08,0 -1.97,-0.89 -1.97,-1.97 0,-1.08 0.89,-1.97 1.97,-1.97zm0 7.23l19.62 0c1.08,0 1.97,0.89 1.97,1.97 0,1.08 -0.89,1.97 -1.97,1.97l-19.62 0c-1.08,0 -1.97,-0.89 -1.97,-1.97 0,-1.08 0.89,-1.97 1.97,-1.97zm-12.1 -3.45l0 -30.59c0,-4.48 3.65,-8.13 8.13,-8.13l14.53 0 0 -8c0,-2.37 -1.92,-4.29 -4.29,-4.29l-27.47 0c-2.37,0 -4.29,1.92 -4.29,4.29l0 42.43c0,2.37 1.92,4.29 4.29,4.29l9.11 0zm13.56 -43l-17.28 0c-1.08,0 -1.97,-0.89 -1.97,-1.97 0,-1.08 0.89,-1.97 1.97,-1.97l17.28 0c1.08,0 1.97,0.89 1.97,1.97 0,1.08 -0.89,1.97 -1.97,1.97z"
        />
      </g>
    </svg>
  );
}
