import style from './style.module.css';

export default function HistorianCabinetSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      width="32.0637mm"
      height="25.1533mm"
      version="1.1"
      viewBox="0 0 176.51 138.47"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
      className={style.svg}
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className={style.svg__fill}
          d="M133.52 129.52c4.56,-0.49 8.59,-3.18 10.92,-7.09 0.12,-0.24 0.23,-0.49 0.34,-0.74l0.07 0.02 0 -0 9.82 -26.12 0 0.11 12.73 -34.14c1.16,-3.12 -1.09,-6.5 -4.45,-6.5l-8.28 0 -8.93 0 -71.26 0c-6.06,0 -11.58,3.81 -13.71,9.43 -2.82,7.99 -5.69,16.01 -8.6,24.01 -2.36,6.5 -4.74,12.98 -7.19,19.45 -1.94,5.13 -3.91,10.32 -6.09,15.34 -0.98,2.27 -2.21,4.38 -3.69,6.23l97.89 0c0.15,0 0.3,-0 0.45,-0.01zm-123.88 5.38l-1.61 -0.61 0.17 -0.43c-4.98,-3.93 -8.19,-10.03 -8.19,-16.85l0 -90.98c0,-0.23 0.01,-0.46 0.03,-0.69l0 -3.73c0,-11.91 9.69,-21.6 21.6,-21.6l29.18 0c10.37,0 19.05,7.34 21.13,17.1l61.13 0c11.91,0 21.6,9.69 21.6,21.61l0 7.56 8.28 0c9.48,0 16,9.52 12.7,18.38l-21.79 58.46c-0.19,0.52 -0.4,1.02 -0.63,1.52 -3.13,8.09 -10.98,13.84 -20.15,13.84l-111.6 0c-4.37,0 -8.43,-1.31 -11.82,-3.56zm4.58 -7.68l1.06 0.4c2.2,0.84 5.55,0.82 7.76,0.12 3.83,-1.21 6.2,-4.4 7.75,-7.96 2.13,-4.9 4.04,-9.97 5.94,-14.97 2.44,-6.43 4.81,-12.89 7.16,-19.35 2.9,-7.97 5.75,-15.96 8.58,-23.96l0.03 -0.09c3.44,-9.09 12.25,-15.16 21.97,-15.16l71.26 0 0 -7.56c0,-7 -5.67,-12.67 -12.66,-12.67l-69.59 0 0 -4.42c0,-7 -5.68,-12.67 -12.67,-12.67l-29.18 0c-6.99,0 -12.66,5.67 -12.66,12.67l0 4.42 -0.03 0 0 90.98c0,4.22 2.09,7.95 5.28,10.22z"
        />
      </g>
    </svg>
  );
}
