import style from './style.module.css';

export default function BlockedFocusedIcon() {
  return (
    <svg
      className={style.linkSvgMedium}
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.602674 7.63717L14.373 13.6055C15.2828 14.0013 17.0524 13.8827 18.2954 13.3434L30.8525 7.90466C32.1002 7.365 32.3737 6.59795 31.4591 6.20214L17.6888 0.234219C16.779 -0.16195 15.0093 -0.0430209 13.7664 0.496282L1.20931 5.93501C-0.0383925 6.47431 -0.311828 7.24136 0.602674 7.63717ZM12.991 7.57993L14.7478 6.41805L12.991 5.25581C12.6087 5.00238 12.6087 4.59211 12.991 4.33836C13.3737 4.08493 13.9982 4.08493 14.3805 4.33836L16.1373 5.5006L17.8941 4.33836C18.2768 4.08493 18.9009 4.08493 19.2836 4.33836C19.6663 4.59211 19.6663 5.00241 19.2836 5.25581L17.5268 6.41805L19.2836 7.57993C19.6663 7.83336 19.6663 8.24399 19.2836 8.49738C18.9009 8.75117 18.2767 8.75117 17.8941 8.49738L16.1373 7.3355L14.3805 8.49738C13.9982 8.75117 13.3737 8.75117 12.991 8.49738C12.6087 8.24395 12.6087 7.83336 12.991 7.57993Z"
        fill="#7D7D7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9057 14.6743L2.21327 9.60683C1.54212 9.83962 -1.19671 10.8442 0.602674 11.621L14.373 17.5893C15.2828 17.9851 17.0524 17.8665 18.2954 17.3272L30.8525 11.8885C32.1002 11.3488 32.3737 10.5817 31.4591 10.1859C30.8327 9.91377 31.116 10.0128 29.8533 9.60686L18.7624 14.4123C17.4105 14.9961 15.2728 15.2682 13.9009 14.6743L13.9057 14.6743Z"
        fill="#7D7D7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7093 13.655L18.7675 18.396C17.4154 18.9798 15.2777 19.252 13.9057 18.6581L2.26829 13.6156L0.950936 14.2192C-0.0731854 14.7488 -0.242175 15.4316 0.602708 15.7978L14.373 21.7661C15.2828 22.1619 17.0525 22.0429 18.2954 21.5036L30.8525 16.0649C32.1006 15.5256 32.2593 14.9516 31.4592 14.3628C30.9174 13.9619 30.7183 13.8235 29.7041 13.6601L29.7093 13.655Z"
        fill="#7D7D7D"
      />
    </svg>
  );
}
