import style from './style.module.css';

export default function HistorianMaterialSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      width="25.7852mm"
      height="31.6398mm"
      version="1.1"
      viewBox="0 0 84.95 104.24"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
      className={style.svg}
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className={style.svg__fill}
          d="M49.6 0l-38.27 0c-6.25,0 -11.33,5.08 -11.33,11.33l0 59.12c0,6.25 5.08,11.33 11.33,11.33l12.69 0 0 11.14c0,6.25 5.08,11.33 11.33,11.33l38.27 0c6.25,0 11.33,-5.08 11.33,-11.33l0 -48.11c0,-1.42 -0.56,-2.78 -1.57,-3.78l-5.42 -5.42 -9.51 -9.52 -0.05 -0.05 -2 -2.01c-1,-1 -2.36,-1.57 -3.78,-1.57l-1.69 0 0 -11.15c0,-6.25 -5.08,-11.33 -11.33,-11.33zm-30.78 11.03l24.08 0c1.51,0 2.74,1.23 2.74,2.74 0,1.51 -1.23,2.74 -2.74,2.74l-24.08 0c-1.51,0 -2.74,-1.23 -2.74,-2.74 0,-1.51 1.23,-2.74 2.74,-2.74zm21.95 20.83l10.82 0c1.51,0 2.74,1.23 2.74,2.74 0,1.51 -1.23,2.74 -2.74,2.74l-10.82 0c-1.51,0 -2.74,-1.23 -2.74,-2.74 0,-1.51 1.23,-2.74 2.74,-2.74zm0.1 18.8l27.33 0c1.51,0 2.74,1.23 2.74,2.74 0,1.51 -1.23,2.74 -2.74,2.74l-27.33 0c-1.51,0 -2.74,-1.23 -2.74,-2.74 0,-1.51 1.23,-2.74 2.74,-2.74zm0 10.43l27.33 0c1.51,0 2.74,1.23 2.74,2.74 0,1.51 -1.23,2.74 -2.74,2.74l-27.33 0c-1.51,0 -2.74,-1.23 -2.74,-2.74 0,-1.51 1.23,-2.74 2.74,-2.74zm0 10.07l27.33 0c1.51,0 2.74,1.23 2.74,2.74 0,1.51 -1.23,2.74 -2.74,2.74l-27.33 0c-1.51,0 -2.74,-1.23 -2.74,-2.74 0,-1.51 1.23,-2.74 2.74,-2.74zm0 10.07l27.33 0c1.51,0 2.74,1.23 2.74,2.74 0,1.51 -1.23,2.74 -2.74,2.74l-27.33 0c-1.51,0 -2.74,-1.23 -2.74,-2.74 0,-1.51 1.23,-2.74 2.74,-2.74zm-16.86 -4.81l-12.69 0c-3.31,0 -5.98,-2.68 -5.98,-5.98l0 -59.12c0,-3.3 2.68,-5.98 5.98,-5.98l38.27 0c3.31,0 5.98,2.68 5.98,5.98l0 11.15 -20.24 0c-6.25,0 -11.33,5.08 -11.33,11.33l0 42.63zm35.72 -48.61c-0.27,0.64 -0.41,1.33 -0.41,2.05l0 6.58c0,6.22 5.06,11.28 11.28,11.28l6.58 0c0.86,0 1.69,-0.21 2.43,-0.58l0 45.77c0,3.3 -2.68,5.98 -5.98,5.98l-38.27 0c-3.31,0 -5.98,-2.68 -5.98,-5.98l0 -59.12c0,-3.3 2.68,-5.98 5.98,-5.98l24.39 0zm10.67 7.78l6.78 6.78 -6.58 0c-3.28,0 -5.93,-2.66 -5.93,-5.93l0 -6.58 5.73 5.73z"
        />
      </g>
    </svg>
  );
}
