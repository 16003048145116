import Header from './Header/index';
import Content from './Content/index';

function History() {
  return (
    <>
      <Header />
      <Content />
    </>
  );
}

export default History;
