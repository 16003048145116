import { useState } from 'react';

import style from './style.module.css';

function ArticleBtn() {
  const [buttonArticleHovered, setButtonArticleHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setButtonArticleHovered(true)}
      onMouseLeave={() => setButtonArticleHovered(false)}
      className={`${style.dialog__button}`}
    >
      {buttonArticleHovered ? (
        <svg viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.68866 4.55295H13.6527C13.9174 4.55295 14.1335 4.79954 14.1335 5.10129C14.1335 5.40303 13.9174 5.64962 13.6527 5.64962H3.68866C3.42389 5.64962 3.20778 5.40303 3.20778 5.10129C3.20778 4.79954 3.42389 4.55295 3.68866 4.55295ZM3.68866 8.81876H13.6527C13.9174 8.81876 14.1335 9.06535 14.1335 9.36709C14.1335 9.66884 13.9174 9.91543 13.6527 9.91543H3.68866C3.42389 9.91543 3.20778 9.66884 3.20778 9.36709C3.20778 9.06535 3.42389 8.81876 3.68866 8.81876ZM10.2139 17.7243C10.2139 14.2137 13.1075 11.3682 16.6773 11.3682C16.9017 11.3682 17.1236 11.3795 17.3422 11.4014V10.2756V9.22352V0.11356C17.3422 0.0535355 17.2877 0 17.2267 0H3.0057L0 3.09776V22.8864C0 22.9465 0.0544386 23 0.115476 23H10.0886H11.2895H13.0712C11.3473 21.8587 10.2139 19.9217 10.2139 17.7243ZM3.68866 13.0846H10.7146C10.4383 13.4269 10.1925 13.7935 9.9805 14.1812H3.68866C3.42389 14.1812 3.20778 13.9346 3.20778 13.6329C3.20778 13.3312 3.42389 13.0846 3.68866 13.0846ZM3.68866 17.3504H9.08308C9.07731 17.4745 9.07318 17.5994 9.07318 17.7243C9.07318 17.9677 9.08556 18.2086 9.10865 18.4462H3.68784C3.42306 18.4462 3.20696 18.1996 3.20696 17.8979C3.20696 17.5962 3.42306 17.3496 3.68784 17.3496L3.68866 17.3504ZM16.6773 12.49C13.7376 12.49 11.3547 14.8334 11.3547 17.7243C11.3547 20.6152 13.7376 22.9586 16.6773 22.9586C19.617 22.9586 22 20.6152 22 17.7243C22 14.8334 19.617 12.49 16.6773 12.49ZM16.3705 19.4853C16.1965 19.6581 15.9284 19.7027 15.7057 19.5972L15.676 19.5835L14.2466 18.1631C14.0222 17.9401 14.0238 17.5775 14.2507 17.3569C14.4775 17.1362 14.8462 17.1379 15.0706 17.3609L15.9738 18.258L18.2841 15.9633C18.5085 15.7403 18.8772 15.7386 19.104 15.9593C19.3308 16.1799 19.3325 16.5425 19.1081 16.7655L16.3697 19.4853H16.3705Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.7462 1.12557V10.3851C17.099 10.4154 17.4443 10.4716 17.7798 10.5529V1.12557C17.7798 0.506989 17.2679 0 16.6416 0H3.31442L0 3.43511V21.8744C0 22.493 0.511923 23 1.13819 23H12.5754C12.0963 22.7125 11.6554 22.3689 11.2623 21.9779H1.13819C1.08289 21.9779 1.03356 21.9291 1.03356 21.8744V3.84454L3.75684 1.02211H16.6416C16.6969 1.02211 16.7462 1.07088 16.7462 1.12557ZM9.2632 16.8311H4.37564C4.13574 16.8311 3.93994 17.0558 3.93994 17.3307C3.93994 17.6057 4.13574 17.8303 4.37564 17.8303H9.28636C9.26544 17.6138 9.25423 17.3943 9.25423 17.1726C9.25423 17.058 9.25722 16.945 9.2632 16.8319V16.8311ZM4.37564 13.9437H10.0763C10.2684 13.5911 10.4918 13.2571 10.7414 12.9445H4.37564C4.13574 12.9445 3.93994 13.1691 3.93994 13.4441C3.93994 13.719 4.13574 13.9437 4.37564 13.9437ZM4.37564 10.057H13.4034C13.6433 10.057 13.8391 9.83233 13.8391 9.55741C13.8391 9.28248 13.6433 9.05781 13.4034 9.05781H4.37564C4.13574 9.05781 3.93994 9.28248 3.93994 9.55741C3.93994 9.83233 4.13574 10.057 4.37564 10.057ZM4.37564 6.17033H13.4034C13.6433 6.17033 13.8391 5.94566 13.8391 5.67074C13.8391 5.39581 13.6433 5.17114 13.4034 5.17114H4.37564C4.13574 5.17114 3.93994 5.39581 3.93994 5.67074C3.93994 5.94566 4.13574 6.17033 4.37564 6.17033ZM16.1439 11.3814C12.9094 11.3814 10.2878 13.974 10.2878 17.1726C10.2878 20.3712 12.9094 22.9638 16.1439 22.9638C19.3784 22.9638 22 20.3712 22 17.1726C22 13.974 19.3784 11.3814 16.1439 11.3814ZM16.1439 12.4035C18.8074 12.4035 20.9664 14.5386 20.9664 17.1726C20.9664 19.8066 18.8074 21.9417 16.1439 21.9417C13.4804 21.9417 11.3214 19.8066 11.3214 17.1726C11.3214 14.5386 13.4804 12.4035 16.1439 12.4035ZM15.5064 17.6589L17.5997 15.5681C17.803 15.3649 18.137 15.3634 18.3426 15.5644C18.5481 15.7654 18.5496 16.0958 18.3463 16.299L15.8651 18.7771C15.7075 18.9345 15.4646 18.9751 15.2628 18.8791L15.2359 18.8665L13.9408 17.5724C13.7375 17.3692 13.739 17.0388 13.9445 16.8378C14.15 16.6368 14.4841 16.6383 14.6873 16.8415L15.5057 17.6589H15.5064Z"
            fill="white"
          />
        </svg>
      )}
      <p>Добавить в мою статью</p>
    </div>
  );
}

export default ArticleBtn;
