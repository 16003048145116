import style from './style.module.css';

function NewFocusedIcon() {
  return (
    <svg
      className={style.linkSvg}
      viewBox="0 0 23 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3721 9.6395C18.3721 10.6523 19.1919 11.4789 20.2093 11.4789H22.2442L20.1453 9.37756L18.3721 7.60217V9.6395ZM11.0058 14.046H19.4709C19.936 14.046 20.3198 14.4301 20.3198 14.8958C20.3198 15.3615 19.936 15.7457 19.4709 15.7457H11.0058C10.5407 15.7457 10.157 15.3615 10.157 14.8958C10.157 14.4301 10.5407 14.046 11.0058 14.046ZM11.0058 17.2766H19.4709C19.936 17.2766 20.3198 17.6608 20.3198 18.1265C20.3198 18.5921 19.936 18.9763 19.4709 18.9763H11.0058C10.5407 18.9763 10.157 18.5921 10.157 18.1265C10.157 17.6608 10.5407 17.2766 11.0058 17.2766ZM7.44186 8.81875V27.1431C7.44186 28.1676 8.27326 29 9.29651 29H21.1453C22.1686 29 23 28.1676 23 27.1431V12.9574C22.7733 13.0739 22.5174 13.1379 22.25 13.1379H20.2151C18.2907 13.1379 16.7209 11.5721 16.7209 9.6395V7.60217C16.7209 7.38097 16.7674 7.1656 16.8488 6.96768H9.29651C8.27326 6.96768 7.44186 7.80008 7.44186 8.81875ZM14.3256 9.91891H10.9767C10.5116 9.91891 10.1279 9.53473 10.1279 9.06905C10.1279 8.60337 10.5116 8.21919 10.9767 8.21919H14.3256C14.7907 8.21919 15.1744 8.60337 15.1744 9.06905C15.1744 9.53473 14.7907 9.91891 14.3256 9.91891ZM11.0058 20.4024H19.4709C19.936 20.4024 20.3198 20.7866 20.3198 21.2523C20.3198 21.718 19.936 22.1022 19.4709 22.1022H11.0058C10.5407 22.1022 10.157 21.718 10.157 21.2523C10.157 20.7866 10.5407 20.4024 11.0058 20.4024ZM11.0058 23.5283H19.4709C19.936 23.5283 20.3198 23.9125 20.3198 24.3782C20.3198 24.8438 19.936 25.228 19.4709 25.228H11.0058C10.5407 25.228 10.157 24.8438 10.157 24.3782C10.157 23.9125 10.5407 23.5283 11.0058 23.5283ZM5.78488 22.0381V8.82457C5.78488 6.88619 7.36047 5.31453 9.2907 5.31453H15.5581V1.85688C15.5581 0.832397 14.7267 0 13.7035 0H1.85465C0.831395 0 0 0.832397 0 1.85688V20.1813C0 21.2057 0.831395 22.0381 1.85465 22.0381H5.78488ZM11.6337 3.46929H4.18023C3.71512 3.46929 3.3314 3.08511 3.3314 2.61943C3.3314 2.15375 3.71512 1.76957 4.18023 1.76957H11.6337C12.0988 1.76957 12.4826 2.15375 12.4826 2.61943C12.4826 3.08511 12.0988 3.46929 11.6337 3.46929Z"
        fill="#7D7D7D"
      />
    </svg>
  );
}

export default NewFocusedIcon;
