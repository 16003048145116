import style from './style.module.css';

export default function SendingSvg() {
  return (
    <svg
      className={style.linkSvg}
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      width="32.0637mm"
      height="25.1533mm"
      version="1.1"
      viewBox="0 0 262.94 206.27"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#7D7D7D"
          d="M198.89 192.95c6.8,-0.72 12.8,-4.74 16.27,-10.56 0.18,-0.36 0.35,-0.73 0.5,-1.11l0.1 0.04 0 -0.01 14.63 -38.91 0 0.17 18.96 -50.85c1.73,-4.65 -1.63,-9.68 -6.62,-9.68l-12.34 0 -13.31 0 -106.16 0c-9.03,0 -17.26,5.67 -20.43,14.05 -4.19,11.9 -8.47,23.84 -12.8,35.77 -3.52,9.68 -7.07,19.34 -10.72,28.97 -2.89,7.63 -5.82,15.37 -9.07,22.86 -1.47,3.38 -3.29,6.52 -5.5,9.28l145.82 0c0.23,0 0.45,-0 0.68,-0.01zm-184.53 8.02l-2.4 -0.92 0.25 -0.64c-7.43,-5.86 -12.2,-14.93 -12.2,-25.1l0 -135.53c0,-0.34 0.01,-0.69 0.04,-1.03l0 -5.56c0,-17.75 14.43,-32.18 32.17,-32.18l43.47 0c15.44,0 28.38,10.94 31.47,25.47l91.06 0c17.74,0 32.17,14.44 32.17,32.19l0 11.26 12.34 0c14.13,0 23.84,14.18 18.92,27.38l-32.47 87.09c-0.29,0.77 -0.6,1.53 -0.94,2.27 -4.66,12.04 -16.36,20.61 -30.02,20.61l-166.25 0c-6.5,0 -12.56,-1.95 -17.61,-5.3zm6.82 -11.44l1.58 0.6c3.28,1.25 8.27,1.22 11.57,0.18 5.7,-1.81 9.24,-6.55 11.54,-11.85 3.17,-7.3 6.02,-14.85 8.84,-22.3 3.63,-9.58 7.16,-19.2 10.66,-28.82 4.32,-11.88 8.57,-23.77 12.78,-35.69l0.05 -0.14c5.13,-13.54 18.24,-22.59 32.72,-22.59l106.16 0 0 -11.26c0,-10.42 -8.45,-18.88 -18.86,-18.88l-103.67 0 0 -6.59c0,-10.43 -8.46,-18.88 -18.87,-18.88l-43.47 0c-10.41,0 -18.86,8.45 -18.86,18.88l0 6.59 -0.04 0 0 135.53c0,6.28 3.11,11.84 7.87,15.22zm111.89 -61.85l15.52 0 0 -15.52c0,-3.66 2.99,-6.65 6.65,-6.65 3.66,0 6.65,2.99 6.65,6.65l0 15.52 15.52 0c3.66,0 6.65,2.99 6.65,6.65 0,3.66 -2.99,6.65 -6.65,6.65l-15.52 0 0 15.52c0,3.66 -2.99,6.65 -6.65,6.65 -3.66,0 -6.65,-2.99 -6.65,-6.65l0 -15.52 -15.52 0c-3.66,0 -6.65,-2.99 -6.65,-6.65 0,-3.66 2.99,-6.65 6.65,-6.65z"
        />
      </g>
    </svg>
  );
}
