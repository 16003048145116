import style from '../../listStyles.module.css';

function ChangeSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      className={style.svg}
      viewBox="0 0 42.8 51.91"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className={style.svg}
          d="M7.47 49.38l-1.34 0c-0.8,0 -1.45,-0.65 -1.45,-1.45l0 -7.49 4.25 0 0 7.49c0,0.8 -0.65,1.45 -1.45,1.45zm-4.94 -13.28c0,-0.43 0.36,-0.79 0.79,-0.79l6.95 0c0.43,0 0.79,0.36 0.79,0.79l0 1.02c0,0.43 -0.36,0.79 -0.79,0.79l-6.95 0c-0.43,0 -0.79,-0.36 -0.79,-0.79l0 -1.02zm3.6 -33.58l1.34 0c0.8,0 1.45,0.65 1.45,1.45l0 28.81 -4.25 0 0 -28.81c0,-0.8 0.65,-1.45 1.45,-1.45zm-3.98 37.7l0 7.71c0,2.2 1.79,3.98 3.98,3.98l1.34 0c2.19,0 3.98,-1.78 3.98,-3.98l0 -7.71c1.25,-0.47 2.14,-1.68 2.14,-3.1l0 -1.02c0,-1.42 -0.89,-2.62 -2.14,-3.1l0 -29.03c0,-2.19 -1.79,-3.98 -3.98,-3.98l-1.34 0c-2.19,0 -3.98,1.79 -3.98,3.98l0 29.03c-1.25,0.48 -2.14,1.68 -2.14,3.1l0 1.02c0,1.42 0.89,2.63 2.14,3.1z"
        />
        <path
          className={style.svg}
          d="M21.81 49.38l-1.34 0c-0.8,0 -1.45,-0.65 -1.45,-1.45l0 -18.96 4.25 0 0 18.96c0,0.8 -0.65,1.45 -1.45,1.45zm-5.07 -24.75c0,-0.43 0.36,-0.79 0.79,-0.79l6.95 0c0.43,0 0.79,0.36 0.79,0.79l0 1.02c0,0.43 -0.36,0.79 -0.79,0.79l-6.95 0c-0.43,0 -0.79,-0.36 -0.79,-0.79l0 -1.02zm3.73 -22.11l1.34 0c0.8,0 1.45,0.65 1.45,1.45l0 17.34 -4.25 0 0 -17.34c0,-0.8 0.65,-1.45 1.45,-1.45zm-3.98 26.28l0 19.13c0,2.2 1.78,3.98 3.98,3.98l1.34 0c2.2,0 3.98,-1.78 3.98,-3.98l0 -19.23c1.18,-0.51 2.01,-1.68 2.01,-3.05l0 -1.02c0,-1.36 -0.83,-2.54 -2.01,-3.05l0 -17.61c0,-2.19 -1.79,-3.98 -3.98,-3.98l-1.34 0c-2.19,0 -3.98,1.79 -3.98,3.98l0 17.51c-1.32,0.44 -2.28,1.68 -2.28,3.15l0 1.02c0,1.47 0.96,2.71 2.28,3.15z"
        />
        <path
          className={style.svg}
          d="M36.68 49.38l-1.34 0c-0.8,0 -1.45,-0.65 -1.45,-1.45l0 -30.16 4.25 0 0 30.16c0,0.8 -0.65,1.45 -1.45,1.45zm-4.94 -35.94c0,-0.43 0.36,-0.79 0.79,-0.79l6.95 0c0.43,0 0.79,0.36 0.79,0.79l0 1.02c0,0.43 -0.36,0.79 -0.79,0.79l-6.95 0c-0.43,0 -0.79,-0.36 -0.79,-0.79l0 -1.02zm3.6 -10.91l1.34 0c0.8,0 1.45,0.65 1.45,1.45l0 6.14 -4.25 0 0 -6.14c0,-0.8 0.65,-1.45 1.45,-1.45zm-3.98 15.03l0 30.37c0,2.2 1.79,3.98 3.98,3.98l1.34 0c2.19,0 3.98,-1.78 3.98,-3.98l0 -30.37c1.25,-0.48 2.15,-1.69 2.15,-3.1l0 -1.02c0,-1.42 -0.89,-2.62 -2.15,-3.1l0 -6.36c0,-2.19 -1.79,-3.98 -3.98,-3.98l-1.34 0c-2.2,0 -3.98,1.79 -3.98,3.98l0 6.36c-1.25,0.48 -2.14,1.68 -2.14,3.1l0 1.02c0,1.42 0.89,2.62 2.14,3.1z"
        />
      </g>
    </svg>
  );
}

export default ChangeSvg;
