import style from './style.module.css';

export default function MaterialFocusedSvg() {
  return (
    <svg
      className={style.linkSvg}
      viewBox="0 0 33 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6339 15.2761L19.3517 20.2331C17.9575 20.8437 15.7533 21.1283 14.3386 20.5074L2.33878 15.2347L0.980408 15.866C-0.0755342 16.4196 -0.249816 17.1337 0.621593 17.5166L14.8204 23.7568C15.7585 24.1708 17.5833 24.0466 18.8648 23.4826L31.8129 17.796C33.0995 17.232 33.2635 16.6318 32.4383 16.016C31.8795 15.5969 31.6745 15.452 30.6288 15.2813L30.6339 15.2761Z"
        fill="#7D7D7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3385 16.342L2.2823 11.0435C1.5903 11.2867 -1.23409 12.3371 0.621498 13.1495L14.8203 19.3897C15.7584 19.8037 17.5832 19.6795 18.8647 19.1155L31.8128 13.4289C33.0994 12.8649 33.3813 12.0628 32.4382 11.6489C31.7923 11.3643 32.0845 11.4678 30.7825 11.0435L19.3465 16.0678C17.9523 16.6784 15.7481 16.963 14.3334 16.342H14.3385Z"
        fill="#7D7D7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.621518 8.9841L14.8204 15.2244C15.7584 15.6383 17.5832 15.5141 18.8647 14.9501L31.8128 9.26351C33.0994 8.69951 33.3814 7.89748 32.4382 7.48353L18.2394 1.24326C17.3013 0.829314 15.4765 0.953499 14.195 1.5175L1.24688 7.20412C-0.0397274 7.76812 -0.321654 8.57015 0.621518 8.9841ZM26.3793 7.83539L14.0156 13.3409C13.626 13.5117 13.1749 13.3357 13.0006 12.9425C12.8315 12.5492 13.0058 12.0939 13.3953 11.918L25.7591 6.41244C26.1487 6.24169 26.5997 6.41762 26.774 6.81087C26.9432 7.20412 26.7689 7.65946 26.3793 7.83539ZM22.622 6.11233L10.2583 11.6178C9.8687 11.7886 9.41762 11.6127 9.24334 11.2194C9.07418 10.8262 9.24846 10.3708 9.63803 10.1949L22.0018 4.68938C22.3914 4.51863 22.8424 4.69456 23.0167 5.08781C23.1859 5.48106 23.0116 5.9364 22.622 6.11233ZM18.9929 4.47206L6.62911 9.97757C6.23954 10.1483 5.78846 9.9724 5.61418 9.57915C5.44502 9.18589 5.6193 8.73055 6.00887 8.55462L18.3726 3.04911C18.7622 2.87836 19.2133 3.05429 19.3876 3.44754C19.5567 3.84079 19.3824 4.29613 18.9929 4.47206Z"
        fill="#7D7D7D"
      />
    </svg>
  );
}
