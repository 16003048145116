import style from '../../listStyles.module.css';

function DocumentSvg() {
  return (
    <svg
      className={style.iconSvg}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2143 16.1326C32.2143 7.24503 25.0202 0 16.1326 0C7.24503 0 0 7.24503 0 16.1326C0 25.0202 7.24503 32.2143 16.1326 32.2143C19.1248 32.2143 22.0407 31.3866 24.5745 29.795L25.0838 29.4767L35.0919 39.523C36.4289 40.8727 38.6317 40.8472 39.9686 39.523C41.331 38.1733 41.3565 35.9959 39.9686 34.659L29.8459 24.5363L30.1388 24.0397C31.5267 21.6332 32.2143 18.9338 32.2143 16.1581V16.1326ZM16.1326 29.5022C8.74751 29.5022 2.71211 23.5177 2.71211 16.1326C2.71211 8.72205 8.72205 2.71211 16.1326 2.71211C23.5177 2.71211 29.5022 8.74751 29.5022 16.1326C29.5022 23.4922 23.4922 29.5022 16.1326 29.5022ZM9.0149 14.5283C8.74751 14.5283 8.54379 14.732 8.54379 14.9994C8.54379 15.2668 8.74751 15.4705 9.0149 15.4705C9.28229 15.4705 9.48602 15.2668 9.48602 14.9994C9.48602 14.732 9.28229 14.5283 9.0149 14.5283ZM10.5556 9.04037C9.48602 9.04037 8.58198 9.86801 8.58198 10.963C8.58198 12.0453 9.47329 12.9366 10.5556 12.9366C11.6124 12.9366 12.4783 12.0199 12.4783 10.963C12.4783 9.90621 11.6124 9.04037 10.5556 9.04037ZM15.254 12.7074C15.636 12.5547 16.0562 12.4655 16.5019 12.4655C18.3609 12.4655 19.9397 13.9807 19.9397 15.8525C19.9397 17.7497 18.3991 19.2904 16.5019 19.2904C14.6047 19.2904 13.1149 17.7497 13.1149 15.8525C13.1149 15.6106 13.1404 15.3686 13.1913 15.1394C12.9366 15.2922 12.6565 15.4196 12.3637 15.5214C12.1727 16.731 11.3196 17.7369 10.1863 18.1699C11.1286 20.7928 13.6497 22.5882 16.5019 22.5882C20.2071 22.5882 23.2376 19.5705 23.2376 15.8525C23.2376 12.1472 20.2199 9.11677 16.5019 9.11677C16.0817 9.11677 15.6742 9.15497 15.2668 9.2441C15.4705 9.77888 15.5724 10.3519 15.5724 10.9503C15.5724 11.5615 15.4578 12.1599 15.2413 12.7074H15.254ZM16.5019 16.9602C17.1258 16.9602 17.6096 16.4764 17.6096 15.8525C17.6096 15.2413 17.1003 14.7956 16.5019 14.7956C15.9161 14.7956 15.445 15.2668 15.445 15.8525C15.445 16.4509 15.8907 16.9602 16.5019 16.9602Z"
        className={style.iconPath}
      />
    </svg>
  );
}

export default DocumentSvg;
