import style from './style.module.css';

export default function HistorianCabinetFocusSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      space="preserve"
      width="28.8402mm"
      height="21.9077mm"
      version="1.1"
      viewBox="0 0 133.14 101.14"
      xlink="http://www.w3.org/1999/xlink"
      xodm="http://www.corel.com/coreldraw/odm/2003"
      className={style.svg}
    >
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className={style.svg__fill}
          d="M36.24 66.74c-1.98,5.45 -3.98,10.89 -6.03,16.31 -1.63,4.3 -3.28,8.65 -5.11,12.87 -0.83,1.9 -1.85,3.67 -3.1,5.22l82.09 0c0.13,0 0.25,-0 0.38,-0.01 3.83,-0.41 7.21,-2.67 9.16,-5.94 0.1,-0.2 0.19,-0.41 0.28,-0.62l0.06 0.02 8.24 -21.91 0 0.09 10.67 -28.63c0.98,-2.62 -0.92,-5.45 -3.73,-5.45l-6.94 0 -7.49 0 -59.77 0c-5.08,0 -9.71,3.19 -11.5,7.91 -2.36,6.7 -4.77,13.42 -7.21,20.14zm-6.94 -2.54c2.43,-6.69 4.83,-13.38 7.19,-20.09l0.03 -0.08c2.89,-7.62 10.27,-12.72 18.42,-12.72l59.77 0 0 -6.34c0,-5.87 -4.76,-10.63 -10.62,-10.63l-58.36 0 0 -3.71c0,-5.87 -4.76,-10.63 -10.63,-10.63l-24.47 0c-5.86,0 -10.62,4.76 -10.62,10.63l0 3.71 -0.02 0 0 76.3c0,3.54 1.75,6.67 4.43,8.57l0.89 0.34c1.85,0.7 4.66,0.69 6.51,0.1 3.21,-1.02 5.2,-3.69 6.5,-6.67 1.78,-4.11 3.39,-8.36 4.98,-12.55 2.04,-5.39 4.03,-10.81 6,-16.23z"
        />
      </g>
    </svg>
  );
}
